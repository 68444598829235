<template>
  <b-container>
    <BackButton back/>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <h1 style="margin-top: 4.25rem; margin-bottom: 3rem;">Bug Report</h1>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="margin-bottom: 2rem;">Wenn du ein Problem hast beschreib uns dies hier oder erstell direkt eine GitHub issue.</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row v-show="!created">
      <b-col cols="1"/>
      <b-col cols="10">
        <b-form-textarea
          v-model="text"
          placeholder="Beschreib uns dein Problem"
          rows="3"
          max-rows="6"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row v-show="!created">
      <b-col cols="1"/>
      <b-col cols="10">
        <b-button variant="primary" class="buttonSpacing" style="width: 100%; margin-top: 2rem;" @click="createBugReport">BugReport erstellen</b-button>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row  v-show="!created">
      <b-col cols="1"/>
      <b-col cols="10">
        <!-- clears the local storage -->
        <p class="txtButton" @click="clearStorage">Speicher leeren</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row v-show="created">
      <b-col cols="1"/>
      <b-col cols="10">
        <h4>Deine Meldung:</h4>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row v-show="created">
      <b-col cols="1"/>
      <b-col cols="10">
        <p>{{text}}</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
  </b-container>
</template>

<script>
import BackButton from '../components/BackButton';
export default {
  name: 'BugReport',
  data() {
    return {
      text: '',
      created: false,
    }
  },
  components: {
    BackButton,
  },
  methods: {
    createBugReport() {
      // creates a bug report and sends it to the backend
      this.created = true;
      this.axios.post('/bugReports/createBugReport', {
            text: this.text,
            }, {
            headers: {
              token: localStorage.getItem('token')
            }
        })
          .then((res) => {
            if (res.status === 200) {
              this.toast('BugReport erstellt', 'Vielen Dank für dein Feedback!', 'success')
            } else {
              this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut', 'danger')
            }
          })
          .catch((err) => {
            console.error(err);
            this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut', 'danger')
          });
    },
    clearStorage() {
      // clears the local app storage if errors / bugs accure
      localStorage.clear();
      this.$router.push('/start')
        .then(()=>this.toast('Speicher geleert', 'Bitte melde dich erneut an.', 'info'));
    }
  }
}
</script>

<style>

</style>